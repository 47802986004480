"use strict";

/***********************************************
 * Const
 * ********************************************/
var html = document.documentElement;
var body = document.body; // ヘッダーの色変更 下層ページの判定

var header = document.getElementById('js-header'); // ハンバーガーボタン

var hamburgerButton = document.getElementById('js-hamburgerButton'); // ページ内リンク

var headerHeight = document.querySelector('.l-header').clientHeight;
var smoothOffset = headerHeight; //Fixされているヘッダーの高さ分スクロール先の座標をずらすために、変数に高さを入れておく

var smoothScrollTrigger = document.querySelectorAll('a[href^="#"]'); // カルーセルスライダー

var memberSlider = document.querySelector('.p-memberSlider');
/***********************************************
 * Method
 * ********************************************/
//ローディング

var loadedClass = function loadedClass() {
  setTimeout(function () {
    body.classList.add('loaded');
  }, 100);
};

window.addEventListener('load', function () {
  loadedClass();
}); // ------------------------ ハンバーガーメニュー

function openHeaderNav() {
  hamburgerButton.addEventListener('click', function () {
    var headerNavOpened = body.classList.contains('is-navOpen');

    if (headerNavOpened) {
      body.classList.remove('is-navOpen');
      html.style.overflow = '';
    } else {
      body.classList.add('is-navOpen');
      html.style.overflow = 'hidden';
    }
  });
}

if (hamburgerButton) {
  openHeaderNav();
} // ------------------------ ページ内リンク

/**
 * スムーススクロール実行関数
 */


var _loop = function _loop(i) {
  smoothScrollTrigger[i].addEventListener('click', function (e) {
    e.preventDefault();
    var href = smoothScrollTrigger[i].getAttribute('href');
    var targetElement = document.getElementById(href.replace('#', ''));
    var rect = targetElement.getBoundingClientRect().top;
    var offset = window.pageYOffset;
    var gap = smoothOffset;
    var target = rect + offset - gap;
    window.scrollTo({
      top: target,
      behavior: 'smooth'
    });
  });
};

for (var i = 0; i < smoothScrollTrigger.length; i++) {
  _loop(i);
} //scroll magic


var controller = new ScrollMagic.Controller();
var scrollZoomUp = document.querySelectorAll(".js-scrollZoomUp");
scrollZoomUp.forEach(function (scrollZoomUp) {
  //tween作成
  //console.dir(scrollZoomUp);
  var tween = TweenMax.from(scrollZoomUp.children, 1.0, {
    ease: Power4.easeOut,
    scale: 1.1,
    opacity: 0
  }); //シーンを作成

  var scene = new ScrollMagic.Scene({
    triggerElement: scrollZoomUp,
    reverse: false //triggerHook: "onCenter"

  }).setTween(tween) //.addIndicators('scrollZoomUp')
  .addTo(controller);
}); //フェードイン

var fadeInUps = document.querySelectorAll(".js-fadeInUp");
fadeInUps.forEach(function (fadeInUp) {
  //tween作成
  //console.dir(scrollZoomUp);
  var tween = TweenMax.from(fadeInUp, 1.0, {
    ease: Power4.easeOut,
    y: "20%",
    opacity: 0
  }); //シーンを作成

  var scene = new ScrollMagic.Scene({
    triggerElement: fadeInUp,
    reverse: false //triggerHook: "onCenter"

  }).setTween(tween) //.addIndicators('scrollZoomUp')
  .addTo(controller);
}); //ズームイン

var zoomIns = document.querySelectorAll(".js-zoomIn");
zoomIns.forEach(function (zoomIn) {
  //tween作成
  //console.dir(scrollZoomUp);
  var tween = TweenMax.from(zoomIn, 1.0, {
    ease: Power4.easeOut,
    scale: 0,
    opacity: 0
  }); //シーンを作成

  var scene = new ScrollMagic.Scene({
    triggerElement: zoomIn,
    reverse: false,
    triggerHook: "onEnter"
  }).setTween(tween) //.addIndicators('scrollZoomUp')
  .addTo(controller);
}); //スタッガー（順番に回転しながら表示）

var staggerShows = document.querySelectorAll(".js-staggerShow");
staggerShows.forEach(function (staggerShow) {
  //tween作成
  console.dir(staggerShow);
  var tween = TweenMax.staggerFromTo(staggerShow.children, 0.5, {
    opacity: 0,
    x: "20%",
    rotate: 45
  }, {
    opacity: 1,
    x: "0%",
    rotate: 0
  }, 0.4); //シーンを作成

  var scene = new ScrollMagic.Scene({
    triggerElement: staggerShow,
    reverse: false,
    duration: 300 //triggerHook: "onCenter"

  }).setTween(tween) // .addIndicators('scrollZoomUp')
  .addTo(controller);
}); //スタッガー（順番に回転しながら表示）

var staggerShowUps = document.querySelectorAll(".js-staggerShowUp");
staggerShowUps.forEach(function (staggerShowUp) {
  //tween作成
  console.dir(staggerShowUp);
  var tween = TweenMax.staggerFromTo(staggerShowUp.children, 0.5, {
    opacity: 0,
    y: "20%"
  }, {
    opacity: 1,
    y: "0%"
  }, 0.4); //シーンを作成

  var scene = new ScrollMagic.Scene({
    triggerElement: staggerShowUp,
    reverse: false,
    duration: 300 //triggerHook: "onCenter"

  }).setTween(tween) // .addIndicators('scrollZoomUp')
  .addTo(controller);
}); //ページネーションにアンカーリンクを付与する（案件実績）

var pageLinks = document.querySelectorAll('#works .wp-pagenavi a');

if (pageLinks) {
  pageLinks.forEach(function (link, i) {
    link.setAttribute('href', link.getAttribute('href') + '#works');
  });
}